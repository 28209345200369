import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./CarouselShowCase.css";
import check from "../../img/beenhere.svg";
import device from "../../img/devices_other.svg";
import dolar from "../../img/local_atm.svg";

// const handleDragStart = (e) => e.preventDefault();

const items = [
  {
    img: check,
    description: "Deseja realizar uma oferta específica para um cliente?",
  },
  {
    img: device,
    description: "Tem um produto parado em seu estoque (SKU)?",
  },
  {
    img: dolar,
    description: "Quer impulsionar a oferta de um produto?",
  },
];

console.log(items.img);

const Card = ({ img, description }) => (
  <div className="cardClientBox">
    <div className="clientDiv">
      <img src={img} alt="icon" className="clientIcon" />
      <p className="clientParagraph">{description}</p>
    </div>
  </div>
);

const Gallery = () => {
  const carouselRef = useRef(null);

  const responsive = {
    0: { items: 1 },
    836: { items: 2 },
  };

  const renderedItems = items.map((item, index) => (
    <Card
      key={index}
      img={item.img}
      description={item.description}
      index={item.index}
    />
  ));

  // console.log(Card.index);
  return (
    <AliceCarousel
      mouseTracking
      items={renderedItems}
      ref={carouselRef}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlayInterval={3000}
    />
  );
};

export default Gallery;
