import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

//Styles
import styles from "./Forms.module.css";

//Images and Icons
import { BsFillPersonFill } from "react-icons/bs";
import { MdCardTravel } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Forms = () => {
  const [nome, setNome] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const origin = "ecomtraffega.app.br";

  const submitForm = (formEvent) => {
    // console.log("formEvent", formEvent);
    setLoader(true);

    formEvent.preventDefault();

    const submitParams = {
      nome,
      empresa,
      telefone,
      email,
      origin,
    };
    const submitUrl = "https://form.ecomtraffega.com.br/op/forms/send";

    //const qs = new URLSearchParams(submitParams).toString();

    axios
      .post(submitUrl, submitParams)
      .then(function (response) {
        // alert("sucesso");
        setLoader(false);
        toast.success("Formulário enviado com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setEmail("");
        setEmpresa("");
        setNome("");
        setTelefone("");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error("Tente novamente mais tarde.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.textForms}>
        <h1 className={styles.titleContext}>
          Maior <span>assertividade</span>, mais <span>consumidores</span> e
          mais vendas!
        </h1>
        <p className={styles.paragraphContent}>
          Veja em tempo real a necessidade da persona que acessa o seu site.
        </p>
        <p className={styles.paragraphContent}>
          A <span>E-comtraffega</span> é uma plataforma, para você que deseja
          alavancar o seu e-commerce.
        </p>
        <p className={styles.paragraphContent}>
          Entenda o comportamento do seu cliente através de dados de navegação,
          exibindo showcases temáticos em diversas categorias, automatizando
          processos, definindo a persona com interesse de compra, aumentando a
          taxa de conversão (ROI) e reduzindo custos de mídia.
        </p>
      </div>

      <div className={styles.forms}>
        <div className={styles.textTitleForms}>
          <h2 className={styles.titleForms}>
            Nunca foi tão <span>fácil</span> começar
          </h2>
          <p className={styles.paragraphForms}>Agende sua Demo</p>
        </div>

        <form
          className={styles.textFields}
          onSubmit={submitForm}
          method="post"
          id="forms"
        >
          <div className={styles.fields}>
            <input
              type="text"
              placeholder="Nome"
              name="nome"
              id="nome"
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <BsFillPersonFill className={styles.iconText} />
          </div>

          {!loader ? (
            ""
          ) : (
            <div className={styles.containerLoader}>
              <div className={styles.customLoader}></div>
            </div>
          )}

          <div className={styles.fields}>
            <input
              type="text"
              placeholder="Empresa"
              name="empresa"
              id="empresa"
              required
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
            />
            <MdCardTravel className={styles.iconText} />
          </div>

          <div className={styles.fields}>
            <input
              type="text"
              placeholder="Telefone"
              name="telefone"
              id="telefone"
              required
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
            <FaPhoneAlt className={styles.iconText} />
          </div>

          <div className={styles.fields}>
            <input
              type="text"
              placeholder="E-mail"
              name="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <MdEmail className={styles.iconText} />
          </div>

          <input type="hidden" name="msgemailid" value="15" />
          <input type="hidden" name="clientId" value="16_504c546092" />
          <input
            type="hidden"
            name="redirect"
            value="https://LPsecomtraffega.com.br"
          />

          <div className={styles.policies}>
            <p className={styles.paragraphPolicies}>
              • Ao preencher o formulário, concordo em receber comunicações.
            </p>
            <p className={styles.paragraphPolicies}>
              • Informando meus dados, concordo com a Política de privacidade.
            </p>
          </div>

          <button
            type="submit"
            className={styles.btnForm}
            onClick={console.log("teste")}
          >
            Enviar
          </button>

          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default Forms;
