import React from "react";

//Styles
import styles from "./Management.module.css";

//Image and Icons
import Metricas from "../../img/Métricas.png";

const Management = () => {
  function toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className={styles.management}>
      <div className={styles.divText}>
        <h1 className={styles.titleManagement}>
          <span>Gerenciamento</span> <br /> dos seus <span>Showcases</span>
        </h1>

        <p className={styles.paragraphManagement}>
          Realize a análise das suas campanhas de forma dinâmica e
          personalizada, gerando Reports de acordo com as necessidades do seu
          negócio.
        </p>

        <img src={Metricas} alt="Computador" className={styles.metricas} />

        <p className={styles.paragraphManagement}>
          Oferecemos os melhores recursos para o seu e-commerce, Reports
          personalizados, períodos de análise, mapas interativos, categoria mais
          acessada, dispositivo acessado e comparativos.
        </p>
        <p className={styles.paragraphManagement}>
          Saiba de onde os <span>Showcases</span> tem mais visualizações e gere
          campanhas assertivas, identifique os leads anônimos, analise a
          quantia de acessos via desktop ou mobile, saiba em tempo real quantas
          pessoas estão interagindo e convertendo.
        </p>
        <p className={styles.paragraphFinal}>
          O controle do seu e-commerce nas suas mãos.
        </p>
      </div>

      <button className={styles.btnManagement} onClick={toTop}>Comece agora</button>
    </div>
  );
};

export default Management;
