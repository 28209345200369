import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Forms from '../../components/Forms/Forms';

//Styles
import styles from './ContextForm.module.css';

const ContextForm = () => {
  return (
    <div className={styles.ContextForm}>
        <Navbar />
        <Forms />
    </div>
  )
}

export default ContextForm