import './App.css';
import ContextForm from './pages/ContextForm/ContextForm';
import ShowCaseSection from './pages/ShowCaseSection/ShowCaseSection'; 
import ManagementFooter from './pages/ManagementFooter/ManagementFooter';
import CookieBar from './components/CookieBar/CookieBar';

function App() {
  return (
    <div className="App">
      <CookieBar/>
      <ContextForm />
      <ShowCaseSection/>
      <ManagementFooter />
    </div>
  );
}

export default App;
