import React from "react";
import Management from "../../components/Management/Management";
import Footer from '../../components/Footer/Footer';

//Styles
import styles from './managementFooter.module.css';

const ManagementFooter = () => {
  return (
    <div className={styles.managementFooter}>
      <Management />
      <Footer />
    </div>
  );
};

export default ManagementFooter;
