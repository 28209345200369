import "./reset.css";
import styles from "./ShowCaseSection.module.css";
import laptop from "../../img/pc-show-case 1.svg";
import imgLeft from "../../img/4-linhas-esquerda 1.svg";
import imgRight from "../../img/4-linhas-direita 1.svg";
import Carousel from "../../components/CarouselShowCase/CarouselShowCase";

const ShowCaseSection = () => {
  return (
    <section className={styles.containerSection}>
      <img src={imgLeft} alt="icon" className={styles.imgLeft} />
      <div className={styles.mainContent}>
        <div className={styles.title}>
          <h1>ShowCase</h1>
          <h1>Personalizado + Resultados</h1>
        </div>

        <Carousel />
        <div className={styles.cardContainer}>
          <div className={styles.cardIndividual}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <mask
                id="mask0_121_15"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <rect width="40" height="40" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_121_15)">
                <path
                  d="M20 38.3333L8 29.3333C7.58333 29.0278 7.25695 28.6389 7.02083 28.1666C6.78472 27.6944 6.66667 27.1944 6.66667 26.6666V6.66665C6.66667 5.74998 6.99306 4.96526 7.64583 4.31248C8.29861 3.6597 9.08333 3.33331 10 3.33331H30C30.9167 3.33331 31.7014 3.6597 32.3542 4.31248C33.0069 4.96526 33.3333 5.74998 33.3333 6.66665V26.6666C33.3333 27.1944 33.2153 27.6944 32.9792 28.1666C32.7431 28.6389 32.4167 29.0278 32 29.3333L20 38.3333ZM20 34.1666L30 26.6666V6.66665H10V26.6666L20 34.1666ZM18.25 25L27.6667 15.5833L25.3333 13.1666L18.25 20.25L14.75 16.75L12.3333 19.0833L18.25 25Z"
                  fill="#303866"
                />
              </g>
            </svg>

            <p>Deseja realizar uma oferta específica para um cliente?</p>
          </div>

          <div className={styles.cardIndividual}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <mask
                id="mask0_121_23"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <rect width="40" height="40" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_121_23)">
                <path
                  d="M11.6667 33.3334H6.66666C5.74999 33.3334 4.96527 33.007 4.31249 32.3542C3.65972 31.7014 3.33333 30.9167 3.33333 30V10C3.33333 9.08335 3.65972 8.29863 4.31249 7.64585C4.96527 6.99308 5.74999 6.66669 6.66666 6.66669H33.3333V10H6.66666V30H11.6667V33.3334ZM18.3333 29.1667C19.0278 29.1667 19.618 28.9236 20.1042 28.4375C20.5903 27.9514 20.8333 27.3611 20.8333 26.6667C20.8333 25.9722 20.5903 25.382 20.1042 24.8959C19.618 24.4097 19.0278 24.1667 18.3333 24.1667C17.6389 24.1667 17.0486 24.4097 16.5625 24.8959C16.0764 25.382 15.8333 25.9722 15.8333 26.6667C15.8333 27.3611 16.0764 27.9514 16.5625 28.4375C17.0486 28.9236 17.6389 29.1667 18.3333 29.1667ZM15 33.3334V30.375C14.4722 29.9028 14.0625 29.3472 13.7708 28.7084C13.4792 28.0695 13.3333 27.3889 13.3333 26.6667C13.3333 25.9445 13.4792 25.2639 13.7708 24.625C14.0625 23.9861 14.4722 23.4306 15 22.9584V20H21.6667V22.9584C22.1944 23.4306 22.6042 23.9861 22.8958 24.625C23.1875 25.2639 23.3333 25.9445 23.3333 26.6667C23.3333 27.3889 23.1875 28.0695 22.8958 28.7084C22.6042 29.3472 22.1944 29.9028 21.6667 30.375V33.3334H15ZM35 33.3334H26.6667C26.1944 33.3334 25.7986 33.1736 25.4792 32.8542C25.1597 32.5347 25 32.1389 25 31.6667V16.6667C25 16.1945 25.1597 15.7986 25.4792 15.4792C25.7986 15.1597 26.1944 15 26.6667 15H35C35.4722 15 35.868 15.1597 36.1875 15.4792C36.5069 15.7986 36.6667 16.1945 36.6667 16.6667V31.6667C36.6667 32.1389 36.5069 32.5347 36.1875 32.8542C35.868 33.1736 35.4722 33.3334 35 33.3334ZM28.3333 30H33.3333V18.3334H28.3333V30Z"
                  fill="#303866"
                />
              </g>
            </svg>

            <p>Tem um produto parado em seu estoque (SKU)?</p>
          </div>

          <div className={styles.cardIndividual}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <mask
                id="mask0_121_9"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
              >
                <rect width="40" height="40" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_121_9)">
                <path
                  d="M18.3333 28.3334H21.6666V26.6667H23.3333C23.8055 26.6667 24.2014 26.507 24.5208 26.1875C24.8403 25.8681 25 25.4722 25 25V20C25 19.5278 24.8403 19.132 24.5208 18.8125C24.2014 18.4931 23.8055 18.3334 23.3333 18.3334H18.3333V16.6667H25V13.3334H21.6666V11.6667H18.3333V13.3334H16.6666C16.1944 13.3334 15.7986 13.4931 15.4791 13.8125C15.1597 14.132 15 14.5278 15 15V20C15 20.4722 15.1597 20.8681 15.4791 21.1875C15.7986 21.507 16.1944 21.6667 16.6666 21.6667H21.6666V23.3334H15V26.6667H18.3333V28.3334ZM6.66665 33.3334C5.74998 33.3334 4.96526 33.007 4.31248 32.3542C3.6597 31.7014 3.33331 30.9167 3.33331 30V10C3.33331 9.08335 3.6597 8.29863 4.31248 7.64585C4.96526 6.99308 5.74998 6.66669 6.66665 6.66669H33.3333C34.25 6.66669 35.0347 6.99308 35.6875 7.64585C36.3403 8.29863 36.6666 9.08335 36.6666 10V30C36.6666 30.9167 36.3403 31.7014 35.6875 32.3542C35.0347 33.007 34.25 33.3334 33.3333 33.3334H6.66665ZM6.66665 30H33.3333V10H6.66665V30Z"
                  fill="#303866"
                />
              </g>
            </svg>

            <p>Quer impulsionar a oferta de um produto?</p>
          </div>
        </div>

        <div className={styles.containerImg}>
          <p>
            A <span>E-comtraffega</span> oferece diversas opções de Showcase
            para atrair mais clientes.
          </p>
          <img src={laptop} alt="img laptop" />
          <p>
            Com um <span>Showcase</span> personalizado, você consegue destacar
            produtos, oferecendo descontos ou ofertas exclusivas, incentivando
            os clientes a compra.
          </p>
        </div>
      </div>
      <img src={imgRight} alt="icon" className={styles.imgRight} />
    </section>
  );
};

export default ShowCaseSection;
