import React from "react";
import BlueLogo from "../../img/logo-color.png";

//Styles
import styles from "./Navbar.module.css";

//Images and Icons
import { FaWhatsapp } from "react-icons/fa";

const Navbar = () => {

  return (
    <div className={styles.navBar}>
      <a href="/">
        <img
          className={styles.blueLogo}
          src={BlueLogo}
          alt="E-Comtraffega Azul"
        />
      </a>
      <a
        className={styles.contactUs}
        target="_blank"
        href="https://wa.me/5511992089747?text=Gostaria+de+ter+mais+informa%C3%A7%C3%B5es+sobre+nossos+produtos+%3F"
        rel="noreferrer"
      >
        Contate-nos <FaWhatsapp className={styles.iconContactUs} />
      </a>
    </div>
  );
};

export default Navbar;
